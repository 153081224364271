.none {
  display: none;
}

.block {
  display: block;
}

@media (min-width: 768px) {
  .sm-block {
    display: block;
  }
  .sm-none {
    display: none;
  }
}
