.section {
  margin-top: var(--s);
  margin-bottom: var(--s);
  color: var(--black);
}
.grid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  order: -1;
}
.year {
  display: none;
}
.title {
  overflow: hidden;
  margin: calc(-1 * var(--xs)) 0;
}
.title > h1 {
  width: 420vw;
  display: flex;
  justify-content: space-around;
  animation: move 10s linear infinite;
}
.title > h1 > span {
  display: none;
}
.title > h1::before,
.title > h1::after {
  content: 'Instagram Summit 2022';
  padding: var(--xs);
  white-space: nowrap;
}
.d {
  display: none;
}

@media (min-width: 768px) {
  .section {
    margin-top: var(--xs);
  }
  .d {
    display: unset;
  }
  .m {
    display: none;
  }
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    order: unset;
  }
  .year {
    display: block;
  }
  .title {
    overflow: visible;
    margin: 0;
  }
  .title > h1 {
    display: block;
    width: auto;
    animation: none;
  }
  .title > h1 > span {
    display: inherit;
  }
  .title > h1::before,
  .title > h1::after {
    content: none;
  }
}

@keyframes move {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-210vw, 0, 0);
  }
}
