@import url('https://rsms.me/inter/inter.css');

h1,
.text-xxl {
  font-family: 'ProtoGrotesk', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18vw;
  line-height: 0.9;
  letter-spacing: -0.05em;
}
h2,
.text-xl {
  font-family: 'ProtoGrotesk', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3.6rem;
  line-height: 1;
  letter-spacing: -0.05em;
}
h3,
.text-l {
  font-family: 'ProtoGrotesk', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 1;
  letter-spacing: -0.05em;
}
h4,
.text-m-accident {
  font-family: 'ProtoGrotesk', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 1;
  letter-spacing: -0.05em;
}
.text-m {
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1.2;
  letter-spacing: -0.05em;
}
.text-m-bolder {
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.2;
  letter-spacing: -0.05em;
}
.text-s {
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: -0.05em;
}
.text-xs {
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.2;
  letter-spacing: -0.05em;
}
.text-action {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2;
  letter-spacing: -0.05em;
}

@media (min-width: 768px) {
  h1,
  .text-xxl {
    font-size: calc(12.75vw - var(--xxxs));
  }
  h2,
  .text-xl {
    font-size: 9.6rem;
  }
  h3,
  .text-l {
    font-size: 6.4rem;
  }
  h4,
  .text-m-accident {
    font-size: 3.6rem;
  }
  .text-m {
    font-size: 3.6rem;
  }
  .text-m-bolder {
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 3.6rem;
  }
  .text-s {
    font-size: 2.4rem;
  }
  .text-xs {
    font-size: 1.6rem;
  }
  .text-action {
    font-size: 2rem;
  }
  .t-text-action {
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.05em;
    font-size: 2rem;
  }
}

.text-center {
  text-align: center;
}
.text-white {
  color: var(--white);
}
.text-black {
  color: var(--black);
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

/* Remove default margin */
body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}
article > ul > li + li,
article > ol > li + li {
  margin-top: 0.25em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}

:root {
  --black: #020202;
  --white: white;
  --red: #c92d47;
  --green: #106b2c;
  --back: #f0bfd6;
  --front: #020202;
  --color-transition: background-color 2s ease, color 0.2s ease, fill 0.2s ease;

  --xxl: 6rem;
  --xl: 4rem;
  --l: 3rem;
  --m: 3rem;
  --s: 2rem;
  --xs: 1.5rem;
  --xxs: 1rem;
  --xxxs: 0.6rem;
}

@media (min-width: 768px) {
  :root {
    --xxl: 12rem;
    --xl: 10rem;
    --l: 7rem;
    --m: 6rem;
    --s: 4rem;
    --xs: 3rem;
    --xxs: 1.5rem;
    --xxxs: 1rem;
  }
}

@media (max-width: 374px) {
  html {
    font-size: 9px;
  }
}
@media (min-width: 375px) and (max-width: 767px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  html {
    font-size: 7px;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  html {
    font-size: 8px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  html {
    font-size: 9px;
  }
}
@media (min-width: 1440px) and (max-width: 1599px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 1600px) and (max-width: 1919px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 1920px) and (max-width: 2399px) {
  html {
    font-size: 11px;
  }
}
@media (min-width: 2400px) {
  html {
    font-size: 12px;
  }
}

.grid {
  display: grid;
}
.gap {
  gap: var(--m);
}
.gap_xs {
  gap: var(--xs);
}
.gap_xxs {
  gap: var(--xxs);
}
.gap-y {
  row-gap: var(--m);
}
.grid_2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid_3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid_4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex {
  display: flex;
}
.fdc {
  flex-direction: column;
}
.fdr {
  flex-direction: row;
}
.aic {
  align-items: center;
}
.aisth {
  align-items: stretch;
}
.acc {
  align-content: center;
}
.jcc {
  justify-content: center;
}
.jic {
  justify-items: center;
}
.jcsb {
  justify-content: space-between;
}
.shrink_0 {
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .t-flex {
    display: flex;
  }
  .t-grid {
    display: grid;
  }
  .t-grid_2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .t-grid_3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .t-gap_m {
    gap: var(--m);
  }
  .t-gap_xs {
    gap: var(--xs);
  }
  .sticky-column {
    position: -webkit-sticky;
    position: sticky;
    height: -moz-fit-content;
    height: fit-content;
    top: 15rem;
  }
}

@media (min-width: 1024px) {
  .d-jcc {
    justify-content: center;
  }
  .d-grid_4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.none {
  display: none;
}

.block {
  display: block;
}

@media (min-width: 768px) {
  .sm-block {
    display: block;
  }
  .sm-none {
    display: none;
  }
}

.m_y-xl {
  margin-top: var(--xl);
  margin-bottom: var(--xl);
}

.m_x-xs {
  margin-left: var(--xs);
  margin-right: var(--xs);
}
.m_b-s {
  margin-bottom: var(--s);
}
.m_t-xs {
  margin-top: var(--xs);
}
.m_b-xs {
  margin-bottom: var(--xs);
}
.m_b-xxxs {
  margin-bottom: var(--xxxs);
}
.m_b-m {
  margin-bottom: var(--m);
}
.m_b-xl {
  margin-bottom: var(--xl);
}
.m_b-xxl {
  margin-bottom: var(--xxl);
}
.m_t-xxl {
  margin-top: var(--xxl);
}
.m_t-xl {
  margin-top: var(--xl);
}
.m_t-l {
  margin-top: var(--l);
}
.m_t-m {
  margin-top: var(--m);
}
.m_b-xxl {
  margin-bottom: var(--xxl);
}
.m_y-l {
  margin-top: var(--l);
  margin-bottom: var(--l);
}
.m_t-s {
  margin-top: var(--s);
}
.m_r-s {
  margin-right: var(--s);
}
.m_r-xxs {
  margin-right: var(--xxs);
}
.m_y-xs {
  margin-top: var(--xs);
  margin-bottom: var(--xs);
}
.m_y-s {
  margin-top: var(--s);
  margin-bottom: var(--s);
}
.m_b-l {
  margin-bottom: var(--l);
}
.m_x-auto {
  margin-left: auto;
  margin-right: auto;
}
.m_b-xxs {
  margin-bottom: var(--xxs);
}
.m_y-xxs {
  margin-top: var(--xxs);
  margin-bottom: var(--xxs);
}

@media (min-width: 768px) {
  .t-m_y-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.p_a-xs {
  padding: var(--xs);
}
.p_a-xxs {
  padding: var(--xxs);
}
.p_a-s {
  padding: var(--s);
}
.p_a-l {
  padding: var(--l);
}
.p_x-l {
  padding-left: var(--l);
  padding-right: var(--l);
}
.p_x-xs {
  padding-left: var(--xs);
  padding-right: var(--xs);
}
.p_y-xs {
  padding-top: var(--xs);
  padding-bottom: var(--xs);
}
.p_x-s {
  padding-left: var(--s);
  padding-right: var(--s);
}
.p_y-s {
  padding-top: var(--s);
  padding-bottom: var(--s);
}
.p_x-xs {
  padding-left: var(--xs);
  padding-right: var(--xs);
}
.p_x-xxs {
  padding-left: var(--xxs);
  padding-right: var(--xxs);
}
.p_y-xxs {
  padding-top: var(--xxs);
  padding-bottom: var(--xxs);
}
.p_t-xl {
  padding-top: var(--xl);
}
.p_b-xxl {
  padding-bottom: var(--xxl);
}
.p_b-xl {
  padding-bottom: var(--xl);
}
.p_t-xs {
  padding-top: var(--xs);
}
.p_r-s {
  padding-right: var(--s);
}
.p_l-s {
  padding-left: var(--s);
}
.p_t-s {
  padding-top: var(--s);
}

@media (max-width: 767px) {
  .m-m_t-xl {
    margin-top: var(--xl);
  }
}

.bg-white {
  background-color: var(--white);
}
.bg-black {
  background-color: var(--black);
}
.o-hidden {
  overflow: hidden;
}
.scroll-x {
  overflow-x: auto;
}
.scroll-x.p-m_b-scrollbar {
  padding-bottom: var(--s);
  margin-bottom: calc(var(--s) * -1);
}
.scroll-x > .line > * {
  flex-basis: 25%;
  min-width: 62.5%;
}

.anchor {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: -9.3rem;
}

.z_1 {
  z-index: 1;
}

@media (min-width: 520px) {
  .scroll-x > .line > * {
    min-width: 38.75%;
  }
}
@media (min-width: 768px) {
  .article {
    width: calc(50% - var(--xs));
  }
  .anchor {
    top: -20rem;
  }
  .narrow-content {
    width: calc(67% - var(--xs));
  }
}
@media (min-width: 1024px) {
  .scroll-x > .line > * {
    min-width: auto;
  }
}
@media (max-width: 767px) {
  #__next {
    padding-top: 5.5rem;
  }
}

body {
  font-family: 'Inter', Arial, sans-serif;
  color: var(--front);
  background-color: var(--back);
  transition: var(--color-transition);
  will-change: background-color, color;
}
hr {
  display: block;
  margin: 0;
  padding: 0;
  height: 2px;
  background-color: var(--black);
  border: none;
}
a {
  text-decoration: none;
  color: inherit;
}
article > a,
article > p > a,
.link {
  display: inline;
  background-image: linear-gradient(var(--front), var(--front));
  background-position: 0 100%;
  background-size: 100% 2px;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 0.3s ease;
}
@media (hover: hover) {
  article > a:hover,
  article > p > a:hover,
  .link:hover {
    background-position: 100% 100%;
    background-size: 0 2px;
  }
}
.first {
  order: 1;
}

.last {
  order: 2;
}

.button {
  --x: 0;
  --y: 0;
  --scale: 0;
  --opacity: 0;

  width: 100%;
  padding: 1.9rem 3.1rem;
  background: var(--black);
  color: var(--white);
  text-align: center;
  font: 500 1.8rem/1.2 'Inter', Arial, sans-serif;
  letter-spacing: -0.05em;
  position: relative;
  overflow: hidden;
}
.button-area {
  position: absolute;
  width: 200%;
  height: 300%;
  top: -100%;
  left: -50%;
  z-index: 1;
}
.dot {
  position: absolute;
  left: calc(-50% - 10rem);
  top: calc(-100% - 10rem);
  width: 20rem;
  height: 20rem;
  pointer-events: none;
  transform: translate3d(var(--x), var(--y), 0);
  will-change: transform;
}
.dot::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--back);
  transform: scale(var(--scale));
  opacity: var(--opacity);
  border-radius: 50%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  will-change: transform;
}
.button span {
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.button_s {
  width: 100%;
  padding: 2.8rem;
  background: var(--black);
  color: var(--white);
  text-align: center;
  font: 500 2rem/1.2 'Inter', Arial, sans-serif;
}

@media (min-width: 768px) {
  .button {
    width: auto;
    font-size: 3.6rem;
  }
}

.color-transition,
.color-transition::before,
.color-transition::after {
  transition: var(--color-transition);
  will-change: background-color, color;
}

.w {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 160rem;
}

.relative {
  position: relative;
}

.overflow {
  overflow: hidden;
}

.width {
  width: 100%;
}

.height {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.Button_button__BaJeX {
  --x: 0;
  --y: 0;
  --scale: 0;
  --opacity: 0;

  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: none;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.Button_button__BaJeX.Button_disabled__Hddip {
  cursor: default;
  pointer-events: none;
  color: rgba(255,255,255,.3)
}
.Button_button__BaJeX:active {
  transform: scale(0.9);
}
.Button_button_s__fECJ3 {
  padding: 1.9rem;
}
.Button_button_m__DqKag {
  padding: 1.7rem 4rem;
}
.Button_button-area__PwncC {
  position: relative;
}
.Button_button-area__PwncC::before {
  position: absolute;
  width: 200%;
  height: 300%;
  top: -100%;
  left: -50%;
}
.Button_button__BaJeX * {
  position: relative;
  pointer-events: none;
  z-index: 2;
  will-change: transform;
}
.Button_dot__38DFc {
  position: absolute;
  left: -10rem;
  top: -10rem;
  width: 20rem;
  height: 20rem;
  pointer-events: none;
  transform: translate3d(var(--x), var(--y), 0);
  will-change: transform;
  z-index: -1;
  display: none;
}
.Button_dot__38DFc::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--back);
  transform: scale(var(--scale));
  opacity: var(--opacity);
  border-radius: 50%;
  transition: var(--color-transition), transform 0.5s ease, opacity 0.5s ease;
}

@media (min-width: 768px) {
  .Button_button_s__fECJ3 {
    padding: 2.8rem;
  }
  .Button_button_m__DqKag {
    width: auto;
    padding: 2.5rem 7rem;
  }
}
@media (min-width: 1025px) {
  .Button_dot__38DFc {
    display: initial;
  }
  .Button_button-area__PwncC::before {
    content: '';
  }
}
@media (hover: hover) {
  .Button_button__BaJeX > span {
    display: inline-block;
    transform: translate3d(0.75em, 0, 0);
    transition: transform 0.3s ease;
  }
  .Button_button__BaJeX:hover > span {
    transform: translate3d(0em, 0, 0);
  }
  .Button_button__BaJeX::after {
    content: attr(data-sign);
    transform: translate3d(0, 0, 0) scale(0);
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    padding-left: 0.5em;
    display: inline-block;
    text-align: center;
    width: 1.5em;
  }
  .Button_button__BaJeX:hover::after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.CookieBumper_container__V30bK {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background: var(--back);
  transition: var(--color-transition), transform 0.45s ease;
  transform: translate3d(0, 100%, 0);
}

.CookieBumper_container__V30bK.CookieBumper_is-visible__MJtK5 {
  transform: translate3d(0, 0, 0);
}

.CookieBumper_inner___9Ors {
  padding-top: var(--xs);
  padding-bottom: var(--xs);
  text-align: center;
}

.CookieBumper_links__eq04b {
  grid-template-columns: repeat(2, 1fr);
}

.CookieBumper_button__1HtDU {
  width: 100%;
  padding: 1.2rem;
}

@media (min-width: 768px) {
  .CookieBumper_inner___9Ors {
    padding-top: var(--xxs);
    padding-bottom: var(--xxs);
  }
  .CookieBumper_inner___9Ors,
  .CookieBumper_links__eq04b {
    grid-template-columns: repeat(2, max-content);
  }
  .CookieBumper_button__1HtDU {
    padding: 1rem 3rem;
  }
}

.TypoHero_section__9p1zn {
  margin-top: var(--s);
  margin-bottom: var(--s);
  color: var(--black);
}
.TypoHero_grid__1UVuL {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  order: -1;
}
.TypoHero_year__i8j_R {
  display: none;
}
.TypoHero_title__gBV1e {
  overflow: hidden;
  margin: calc(-1 * var(--xs)) 0;
}
.TypoHero_title__gBV1e > h1 {
  width: 420vw;
  display: flex;
  justify-content: space-around;
  animation: TypoHero_move__7W3AW 10s linear infinite;
}
.TypoHero_title__gBV1e > h1 > span {
  display: none;
}
.TypoHero_title__gBV1e > h1::before,
.TypoHero_title__gBV1e > h1::after {
  content: 'Instagram Summit 2022';
  padding: var(--xs);
  white-space: nowrap;
}
.TypoHero_d__eppe6 {
  display: none;
}

@media (min-width: 768px) {
  .TypoHero_section__9p1zn {
    margin-top: var(--xs);
  }
  .TypoHero_d__eppe6 {
    display: unset;
  }
  .TypoHero_m__2qVRE {
    display: none;
  }
  .TypoHero_grid__1UVuL {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    order: unset;
  }
  .TypoHero_year__i8j_R {
    display: block;
  }
  .TypoHero_title__gBV1e {
    overflow: visible;
    margin: 0;
  }
  .TypoHero_title__gBV1e > h1 {
    display: block;
    width: auto;
    animation: none;
  }
  .TypoHero_title__gBV1e > h1 > span {
    display: inherit;
  }
  .TypoHero_title__gBV1e > h1::before,
  .TypoHero_title__gBV1e > h1::after {
    content: none;
  }
}

@keyframes TypoHero_move__7W3AW {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-210vw, 0, 0);
  }
}

.Preloader_wrapper__CyQ_f {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 0;
  padding-bottom: 16.667%;
  transform: translate(-50%, -50%);
}
.Preloader_dot__OCoB1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 33.333%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--black);
  transform: scale(0);
  animation: Preloader_circle__mkYFt 0.5s infinite cubic-bezier(0.66, 0, 0.33, 1) alternate;
}
.Preloader_dot__OCoB1:nth-child(2) {
  left: 33.333%;
  animation-delay: 0.1s;
}
.Preloader_dot__OCoB1:nth-child(3) {
  left: 66.667%;
  animation-delay: 0.2s;
}
@keyframes Preloader_circle__mkYFt {
  to {
    transform: scale(1);
  }
}

.WistiaVideo_wrapper__sM5o6 {
  position: relative;
  flex-basis: 50rem;
  margin: 1.5rem 0;
}

.WistiaVideo_video-container__gKGQJ {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  height: 0;
  background-color: var(--white);
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

.WistiaVideo_video-background__N8WMy {
  position: absolute;
  top: -1.5rem;
  left: -1.5rem;
  bottom: -1.5rem;
  right: -1.5rem;
  pointer-events: none;
}

.WistiaVideo_video-container__gKGQJ .WistiaVideo_wistia-video__THHah,
.WistiaVideo_video-container__gKGQJ .WistiaVideo_thumbnail__hNiaX,
.WistiaVideo_video-container__gKGQJ .WistiaVideo_thumbnail__hNiaX::before,
.WistiaVideo_video-container__gKGQJ .WistiaVideo_iframe__2SxNh {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border: 0;
  background-color: var(--white);
}

.WistiaVideo_video-container__gKGQJ .WistiaVideo_thumbnail__hNiaX {
  cursor: pointer;
  transition: transform 0.6s ease;
}

.WistiaVideo_video-container__gKGQJ .WistiaVideo_thumbnail__hNiaX:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 17%;
  content: '';
  transition: transform 0.3s ease;
  background: url(/_next/static/media/play-button.0b522da8.svg) no-repeat center / contain;
}

@media (min-width: 768px) {
  .WistiaVideo_wrapper__sM5o6 {
    flex-basis: 70%;
    margin: 5rem;
  }
  .WistiaVideo_video-background__N8WMy {
    top: -5rem;
    left: -5rem;
    bottom: -5rem;
    right: -5rem;
  }
}

@media (hover: hover) {
  .WistiaVideo_video-container__gKGQJ .WistiaVideo_thumbnail__hNiaX:hover {
    transform: scale(1.01) translateZ(0);
  }
  .WistiaVideo_video-container__gKGQJ .WistiaVideo_thumbnail__hNiaX:hover::after {
    transform: scale(0.9) translateZ(0);
  }
}

.Navigation_container__9LwJF {
  position: fixed;
  top: -1px;
  z-index: 2;
  transform: translateZ(0);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 var(--xs) var(--xs) var(--xs);
  overflow: hidden;
  max-height: 5.5rem;
  transition: max-height 0.45s ease;
}
.Navigation_container__9LwJF.Navigation_open__zuluX {
  max-height: 81rem;
}
.Navigation_container__9LwJF::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: var(--xs);
  right: var(--xs);
  height: 2px;
  background: var(--black);
}
.Navigation_container__9LwJF::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: var(--back);
  opacity: 1;
}
.Navigation_logo__NRp3g {
  width: calc(12rem + 2 * var(--xs));
  height: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(-3.8%, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 var(--xs);
}
.Navigation_logo__NRp3g > svg {
  width: 100%;
}
.Navigation_links__mg1N1 {
  display: flex;
  flex-direction: column;
  margin-top: 5.5rem;
}
.Navigation_links__mg1N1 > a {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.5rem;
}
.Navigation_links__mg1N1 > a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--black);
  transition: transform 0.3s ease;
  transform: scaleX(0) translateZ(0);
  transform-origin: right;
}
.Navigation_button__uyKX2 {
  font-size: 2rem;
  height: 5.5rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.Navigation_burger__6122j {
  position: absolute;
  top: 0;
  right: 0;
  width: 10rem;
  height: 5.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.Navigation_burger__6122j::before,
.Navigation_burger__6122j::after {
  content: '';
  position: absolute;
  top: 50%;
  right: var(--xs);
  width: 3.6rem;
  height: 2px;
  background-color: var(--front);
  margin: -1px 0;
  overflow: hidden;
  transition: transform 0.3s ease;
}
.Navigation_burger__6122j::before {
  transform: translate3d(0, -4px, 0);
}
.Navigation_burger__6122j::after {
  transform: translate3d(0, 4px, 0);
}
.Navigation_container__9LwJF.Navigation_open__zuluX > .Navigation_burger__6122j::before {
  transform: translate3d(0.5rem, 0, 0) rotate(45deg);
}
.Navigation_container__9LwJF.Navigation_open__zuluX > .Navigation_burger__6122j::after {
  transform: translate3d(0.5rem, 0, 0) rotate(-45deg);
}
.Navigation_fill__IGy87 {
  fill: var(--front);
  transition: var(--color-transition);
  will-change: fill;
}

@media (max-width: 767px) {
  .Navigation_links__mg1N1 > a {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .Navigation_open__zuluX > .Navigation_links__mg1N1 > a {
    opacity: 1;
  }
  .Navigation_open__zuluX > .Navigation_links__mg1N1 > a::before {
    transform: scaleX(1) translateZ(0);
    transition-delay: inherit;
  }
  .Navigation_open__zuluX > .Navigation_links__mg1N1 > a:nth-child(2) {
    transition-delay: 0.025s;
  }
  .Navigation_open__zuluX > .Navigation_links__mg1N1 > a:nth-child(3) {
    transition-delay: 0.05s;
  }
  .Navigation_open__zuluX > .Navigation_links__mg1N1 > a:nth-child(4) {
    transition-delay: 0.075s;
  }
  .Navigation_open__zuluX > .Navigation_links__mg1N1 > a:nth-child(5) {
    transition-delay: 0.1s;
  }
  .Navigation_open__zuluX > .Navigation_links__mg1N1 > a:nth-child(6) {
    transition-delay: 0.125s;
  }
  .Navigation_open__zuluX > .Navigation_links__mg1N1 > a:nth-child(7) {
    transition-delay: 0.15s;
  }
  .Navigation_open__zuluX > .Navigation_links__mg1N1 > a:nth-child(8) {
    transition-delay: 0.175s;
  }
  .Navigation_open__zuluX > .Navigation_links__mg1N1 > a:nth-child(9) {
    transition-delay: 0.2s;
  }
}

@media (min-width: 768px) {
  .Navigation_container__9LwJF {
    position: -webkit-sticky;
    position: sticky;
    margin: 0;
    padding: 1.5rem var(--xs) calc(1.5rem + 2px) var(--xs);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: unset;
  }
  .Navigation_logo__NRp3g {
    width: 21rem;
    padding: 0;
    position: unset;
  }
  .Navigation_links__mg1N1 {
    flex-direction: row;
    align-items: center;
    margin-top: unset;
  }
  .Navigation_button__uyKX2 {
    width: 21rem !important;
    height: 7rem !important;
  }
  .Navigation_burger__6122j {
    display: none;
  }
  .Navigation_links__mg1N1 > a::before {
    content: none;
  }
}

@media (min-width: 768px) and (hover: hover) {
  .Navigation_links__mg1N1 > a::before {
    content: '';
    top: unset;
    bottom: 0.75rem;
    left: var(--xxs);
    right: var(--xxs);
  }
  .Navigation_links__mg1N1 > a:hover::before {
    transform: scaleX(1) translateZ(0);
    transform-origin: left;
  }
}

.Header_container__3JNoS {
  position: fixed;
  top: -1px;
  z-index: 2;
  transform: translateZ(0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem var(--xs) calc(1.5rem + 2px) var(--xs);
  overflow: hidden;
  max-height: 5.5rem;
}
.Header_container__3JNoS::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: var(--xs);
  right: var(--xs);
  height: 2px;
  background: var(--black);
}
.Header_container__3JNoS::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: var(--back);
  opacity: 1;
}
.Header_grid__PfPIo {
  grid-template-columns: 1fr;
}
.Header_logo__1FRrW {
  width: calc(12rem + 2 * var(--xs));
  height: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(-3.8%, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 var(--xs);
}
.Header_logo__1FRrW > svg {
  width: 100%;
}
.Header_button__hnRqq {
  font-size: 1.5rem;
  height: 4rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.Header_fill__9Z6bS {
  fill: var(--front);
  transition: var(--color-transition);
  will-change: fill;
}

@media (min-width: 768px) {
  .Header_container__3JNoS {
    position: -webkit-sticky;
    position: sticky;
    margin: 0;
    padding: 1.5rem var(--xs) calc(1.5rem + 2px) var(--xs);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: unset;
  }
  .Header_grid__PfPIo {
    grid-template-columns: 1fr auto 1fr;
  }
  .Header_logo__1FRrW {
    width: 21rem;
    padding: 0;
    position: unset;
  }
  .Header_links__6uzmh {
    flex-direction: row;
    align-items: center;
    margin-top: unset;
  }
  .Header_button__hnRqq {
    font-size: 2rem;
    width: 21rem !important;
    height: 7rem !important;
  }
}

