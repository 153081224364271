.container {
  position: fixed;
  top: -1px;
  z-index: 2;
  transform: translateZ(0);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 var(--xs) var(--xs) var(--xs);
  overflow: hidden;
  max-height: 5.5rem;
  transition: max-height 0.45s ease;
}
.container.open {
  max-height: 81rem;
}
.container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: var(--xs);
  right: var(--xs);
  height: 2px;
  background: var(--black);
}
.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: var(--back);
  opacity: 1;
}
.logo {
  width: calc(12rem + 2 * var(--xs));
  height: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(-3.8%, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 var(--xs);
}
.logo > svg {
  width: 100%;
}
.links {
  display: flex;
  flex-direction: column;
  margin-top: 5.5rem;
}
.links > a {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.5rem;
}
.links > a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--black);
  transition: transform 0.3s ease;
  transform: scaleX(0) translateZ(0);
  transform-origin: right;
}
.button {
  font-size: 2rem;
  height: 5.5rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.burger {
  position: absolute;
  top: 0;
  right: 0;
  width: 10rem;
  height: 5.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.burger::before,
.burger::after {
  content: '';
  position: absolute;
  top: 50%;
  right: var(--xs);
  width: 3.6rem;
  height: 2px;
  background-color: var(--front);
  margin: -1px 0;
  overflow: hidden;
  transition: transform 0.3s ease;
}
.burger::before {
  transform: translate3d(0, -4px, 0);
}
.burger::after {
  transform: translate3d(0, 4px, 0);
}
.container.open > .burger::before {
  transform: translate3d(0.5rem, 0, 0) rotate(45deg);
}
.container.open > .burger::after {
  transform: translate3d(0.5rem, 0, 0) rotate(-45deg);
}
.fill {
  fill: var(--front);
  transition: var(--color-transition);
  will-change: fill;
}

@media (max-width: 767px) {
  .links > a {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .open > .links > a {
    opacity: 1;
  }
  .open > .links > a::before {
    transform: scaleX(1) translateZ(0);
    transition-delay: inherit;
  }
  .open > .links > a:nth-child(2) {
    transition-delay: 0.025s;
  }
  .open > .links > a:nth-child(3) {
    transition-delay: 0.05s;
  }
  .open > .links > a:nth-child(4) {
    transition-delay: 0.075s;
  }
  .open > .links > a:nth-child(5) {
    transition-delay: 0.1s;
  }
  .open > .links > a:nth-child(6) {
    transition-delay: 0.125s;
  }
  .open > .links > a:nth-child(7) {
    transition-delay: 0.15s;
  }
  .open > .links > a:nth-child(8) {
    transition-delay: 0.175s;
  }
  .open > .links > a:nth-child(9) {
    transition-delay: 0.2s;
  }
}

@media (min-width: 768px) {
  .container {
    position: sticky;
    margin: 0;
    padding: 1.5rem var(--xs) calc(1.5rem + 2px) var(--xs);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: unset;
  }
  .logo {
    width: 21rem;
    padding: 0;
    position: unset;
  }
  .links {
    flex-direction: row;
    align-items: center;
    margin-top: unset;
  }
  .button {
    width: 21rem !important;
    height: 7rem !important;
  }
  .burger {
    display: none;
  }
  .links > a::before {
    content: none;
  }
}

@media (min-width: 768px) and (hover: hover) {
  .links > a::before {
    content: '';
    top: unset;
    bottom: 0.75rem;
    left: var(--xxs);
    right: var(--xxs);
  }
  .links > a:hover::before {
    transform: scaleX(1) translateZ(0);
    transform-origin: left;
  }
}
