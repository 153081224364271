.bg-white {
  background-color: var(--white);
}
.bg-black {
  background-color: var(--black);
}
.o-hidden {
  overflow: hidden;
}
.scroll-x {
  overflow-x: auto;
}
.scroll-x.p-m_b-scrollbar {
  padding-bottom: var(--s);
  margin-bottom: calc(var(--s) * -1);
}
.scroll-x > .line > * {
  flex-basis: 25%;
  min-width: 62.5%;
}

.anchor {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: -9.3rem;
}

.z_1 {
  z-index: 1;
}

@media (min-width: 520px) {
  .scroll-x > .line > * {
    min-width: 38.75%;
  }
}
@media (min-width: 768px) {
  .article {
    width: calc(50% - var(--xs));
  }
  .anchor {
    top: -20rem;
  }
  .narrow-content {
    width: calc(67% - var(--xs));
  }
}
@media (min-width: 1024px) {
  .scroll-x > .line > * {
    min-width: auto;
  }
}
@media (max-width: 767px) {
  #__next {
    padding-top: 5.5rem;
  }
}
