.m_y-xl {
  margin-top: var(--xl);
  margin-bottom: var(--xl);
}

.m_x-xs {
  margin-left: var(--xs);
  margin-right: var(--xs);
}
.m_b-s {
  margin-bottom: var(--s);
}
.m_t-xs {
  margin-top: var(--xs);
}
.m_b-xs {
  margin-bottom: var(--xs);
}
.m_b-xxxs {
  margin-bottom: var(--xxxs);
}
.m_b-m {
  margin-bottom: var(--m);
}
.m_b-xl {
  margin-bottom: var(--xl);
}
.m_b-xxl {
  margin-bottom: var(--xxl);
}
.m_t-xxl {
  margin-top: var(--xxl);
}
.m_t-xl {
  margin-top: var(--xl);
}
.m_t-l {
  margin-top: var(--l);
}
.m_t-m {
  margin-top: var(--m);
}
.m_b-xxl {
  margin-bottom: var(--xxl);
}
.m_y-l {
  margin-top: var(--l);
  margin-bottom: var(--l);
}
.m_t-s {
  margin-top: var(--s);
}
.m_r-s {
  margin-right: var(--s);
}
.m_r-xxs {
  margin-right: var(--xxs);
}
.m_y-xs {
  margin-top: var(--xs);
  margin-bottom: var(--xs);
}
.m_y-s {
  margin-top: var(--s);
  margin-bottom: var(--s);
}
.m_b-l {
  margin-bottom: var(--l);
}
.m_x-auto {
  margin-left: auto;
  margin-right: auto;
}
.m_b-xxs {
  margin-bottom: var(--xxs);
}
.m_y-xxs {
  margin-top: var(--xxs);
  margin-bottom: var(--xxs);
}

@media (min-width: 768px) {
  .t-m_y-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.p_a-xs {
  padding: var(--xs);
}
.p_a-xxs {
  padding: var(--xxs);
}
.p_a-s {
  padding: var(--s);
}
.p_a-l {
  padding: var(--l);
}
.p_x-l {
  padding-left: var(--l);
  padding-right: var(--l);
}
.p_x-xs {
  padding-left: var(--xs);
  padding-right: var(--xs);
}
.p_y-xs {
  padding-top: var(--xs);
  padding-bottom: var(--xs);
}
.p_x-s {
  padding-left: var(--s);
  padding-right: var(--s);
}
.p_y-s {
  padding-top: var(--s);
  padding-bottom: var(--s);
}
.p_x-xs {
  padding-left: var(--xs);
  padding-right: var(--xs);
}
.p_x-xxs {
  padding-left: var(--xxs);
  padding-right: var(--xxs);
}
.p_y-xxs {
  padding-top: var(--xxs);
  padding-bottom: var(--xxs);
}
.p_t-xl {
  padding-top: var(--xl);
}
.p_b-xxl {
  padding-bottom: var(--xxl);
}
.p_b-xl {
  padding-bottom: var(--xl);
}
.p_t-xs {
  padding-top: var(--xs);
}
.p_r-s {
  padding-right: var(--s);
}
.p_l-s {
  padding-left: var(--s);
}
.p_t-s {
  padding-top: var(--s);
}

@media (max-width: 767px) {
  .m-m_t-xl {
    margin-top: var(--xl);
  }
}
