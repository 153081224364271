@import 'reset.css';
@import 'config.css';
@import 'text.css';
@import 'layout.css';
@import 'display.css';
@import 'spaces.css';
@import 'common.css';

body {
  font-family: 'Inter', Arial, sans-serif;
  color: var(--front);
  background-color: var(--back);
  transition: var(--color-transition);
  will-change: background-color, color;
}
hr {
  display: block;
  margin: 0;
  padding: 0;
  height: 2px;
  background-color: var(--black);
  border: none;
}
a {
  text-decoration: none;
  color: inherit;
}
article > a,
article > p > a,
.link {
  display: inline;
  background-image: linear-gradient(var(--front), var(--front));
  background-position: 0 100%;
  background-size: 100% 2px;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size 0.3s ease;
}
@media (hover: hover) {
  article > a:hover,
  article > p > a:hover,
  .link:hover {
    background-position: 100% 100%;
    background-size: 0 2px;
  }
}
.first {
  order: 1;
}

.last {
  order: 2;
}

.button {
  --x: 0;
  --y: 0;
  --scale: 0;
  --opacity: 0;

  width: 100%;
  padding: 1.9rem 3.1rem;
  background: var(--black);
  color: var(--white);
  text-align: center;
  font: 500 1.8rem/1.2 'Inter', Arial, sans-serif;
  letter-spacing: -0.05em;
  position: relative;
  overflow: hidden;
}
.button-area {
  position: absolute;
  width: 200%;
  height: 300%;
  top: -100%;
  left: -50%;
  z-index: 1;
}
.dot {
  position: absolute;
  left: calc(-50% - 10rem);
  top: calc(-100% - 10rem);
  width: 20rem;
  height: 20rem;
  pointer-events: none;
  transform: translate3d(var(--x), var(--y), 0);
  will-change: transform;
}
.dot::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--back);
  transform: scale(var(--scale));
  opacity: var(--opacity);
  border-radius: 50%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  will-change: transform;
}
.button span {
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.button_s {
  width: 100%;
  padding: 2.8rem;
  background: var(--black);
  color: var(--white);
  text-align: center;
  font: 500 2rem/1.2 'Inter', Arial, sans-serif;
}

@media (min-width: 768px) {
  .button {
    width: auto;
    font-size: 3.6rem;
  }
}

.color-transition,
.color-transition::before,
.color-transition::after {
  transition: var(--color-transition);
  will-change: background-color, color;
}

.w {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 160rem;
}

.relative {
  position: relative;
}

.overflow {
  overflow: hidden;
}

.width {
  width: 100%;
}

.height {
  height: 100%;
}

.pointer {
  cursor: pointer;
}
