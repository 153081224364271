.container {
  position: fixed;
  top: -1px;
  z-index: 2;
  transform: translateZ(0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem var(--xs) calc(1.5rem + 2px) var(--xs);
  overflow: hidden;
  max-height: 5.5rem;
}
.container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: var(--xs);
  right: var(--xs);
  height: 2px;
  background: var(--black);
}
.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: var(--back);
  opacity: 1;
}
.grid {
  grid-template-columns: 1fr;
}
.logo {
  width: calc(12rem + 2 * var(--xs));
  height: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(-3.8%, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 var(--xs);
}
.logo > svg {
  width: 100%;
}
.button {
  font-size: 1.5rem;
  height: 4rem !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
.fill {
  fill: var(--front);
  transition: var(--color-transition);
  will-change: fill;
}

@media (min-width: 768px) {
  .container {
    position: sticky;
    margin: 0;
    padding: 1.5rem var(--xs) calc(1.5rem + 2px) var(--xs);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-height: unset;
  }
  .grid {
    grid-template-columns: 1fr auto 1fr;
  }
  .logo {
    width: 21rem;
    padding: 0;
    position: unset;
  }
  .links {
    flex-direction: row;
    align-items: center;
    margin-top: unset;
  }
  .button {
    font-size: 2rem;
    width: 21rem !important;
    height: 7rem !important;
  }
}
