.button {
  --x: 0;
  --y: 0;
  --scale: 0;
  --opacity: 0;

  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: none;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.button.disabled {
  cursor: default;
  pointer-events: none;
  color: rgba(255,255,255,.3)
}
.button:active {
  transform: scale(0.9);
}
.button_s {
  padding: 1.9rem;
}
.button_m {
  padding: 1.7rem 4rem;
}
.button-area {
  position: relative;
}
.button-area::before {
  position: absolute;
  width: 200%;
  height: 300%;
  top: -100%;
  left: -50%;
}
.button * {
  position: relative;
  pointer-events: none;
  z-index: 2;
  will-change: transform;
}
.dot {
  position: absolute;
  left: -10rem;
  top: -10rem;
  width: 20rem;
  height: 20rem;
  pointer-events: none;
  transform: translate3d(var(--x), var(--y), 0);
  will-change: transform;
  z-index: -1;
  display: none;
}
.dot::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--back);
  transform: scale(var(--scale));
  opacity: var(--opacity);
  border-radius: 50%;
  transition: var(--color-transition), transform 0.5s ease, opacity 0.5s ease;
}

@media (min-width: 768px) {
  .button_s {
    padding: 2.8rem;
  }
  .button_m {
    width: auto;
    padding: 2.5rem 7rem;
  }
}
@media (min-width: 1025px) {
  .dot {
    display: initial;
  }
  .button-area::before {
    content: '';
  }
}
@media (hover: hover) {
  .button > span {
    display: inline-block;
    transform: translate3d(0.75em, 0, 0);
    transition: transform 0.3s ease;
  }
  .button:hover > span {
    transform: translate3d(0em, 0, 0);
  }
  .button::after {
    content: attr(data-sign);
    transform: translate3d(0, 0, 0) scale(0);
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    padding-left: 0.5em;
    display: inline-block;
    text-align: center;
    width: 1.5em;
  }
  .button:hover::after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}
