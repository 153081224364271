.container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background: var(--back);
  transition: var(--color-transition), transform 0.45s ease;
  transform: translate3d(0, 100%, 0);
}

.container.is-visible {
  transform: translate3d(0, 0, 0);
}

.inner {
  padding-top: var(--xs);
  padding-bottom: var(--xs);
  text-align: center;
}

.links {
  grid-template-columns: repeat(2, 1fr);
}

.button {
  width: 100%;
  padding: 1.2rem;
}

@media (min-width: 768px) {
  .inner {
    padding-top: var(--xxs);
    padding-bottom: var(--xxs);
  }
  .inner,
  .links {
    grid-template-columns: repeat(2, max-content);
  }
  .button {
    padding: 1rem 3rem;
  }
}
