.wrapper {
  position: relative;
  flex-basis: 50rem;
  margin: 1.5rem 0;
}

.video-container {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  height: 0;
  background-color: var(--white);
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: -1.5rem;
  left: -1.5rem;
  bottom: -1.5rem;
  right: -1.5rem;
  pointer-events: none;
}

.video-container .wistia-video,
.video-container .thumbnail,
.video-container .thumbnail::before,
.video-container .iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  border: 0;
  background-color: var(--white);
}

.video-container .thumbnail {
  cursor: pointer;
  transition: transform 0.6s ease;
}

.video-container .thumbnail:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 17%;
  content: '';
  transition: transform 0.3s ease;
  background: url('./play-button.svg') no-repeat center / contain;
}

@media (min-width: 768px) {
  .wrapper {
    flex-basis: 70%;
    margin: 5rem;
  }
  .video-background {
    top: -5rem;
    left: -5rem;
    bottom: -5rem;
    right: -5rem;
  }
}

@media (hover: hover) {
  .video-container .thumbnail:hover {
    transform: scale(1.01) translateZ(0);
  }
  .video-container .thumbnail:hover::after {
    transform: scale(0.9) translateZ(0);
  }
}
