.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 0;
  padding-bottom: 16.667%;
  transform: translate(-50%, -50%);
}
.dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 33.333%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--black);
  transform: scale(0);
  animation: circle 0.5s infinite cubic-bezier(0.66, 0, 0.33, 1) alternate;
}
.dot:nth-child(2) {
  left: 33.333%;
  animation-delay: 0.1s;
}
.dot:nth-child(3) {
  left: 66.667%;
  animation-delay: 0.2s;
}
@keyframes circle {
  to {
    transform: scale(1);
  }
}
