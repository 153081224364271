.grid {
  display: grid;
}
.gap {
  gap: var(--m);
}
.gap_xs {
  gap: var(--xs);
}
.gap_xxs {
  gap: var(--xxs);
}
.gap-y {
  row-gap: var(--m);
}
.grid_2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid_3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid_4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex {
  display: flex;
}
.fdc {
  flex-direction: column;
}
.fdr {
  flex-direction: row;
}
.aic {
  align-items: center;
}
.aisth {
  align-items: stretch;
}
.acc {
  align-content: center;
}
.jcc {
  justify-content: center;
}
.jic {
  justify-items: center;
}
.jcsb {
  justify-content: space-between;
}
.shrink_0 {
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .t-flex {
    display: flex;
  }
  .t-grid {
    display: grid;
  }
  .t-grid_2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .t-grid_3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .t-gap_m {
    gap: var(--m);
  }
  .t-gap_xs {
    gap: var(--xs);
  }
  .sticky-column {
    position: sticky;
    height: fit-content;
    top: 15rem;
  }
}

@media (min-width: 1024px) {
  .d-jcc {
    justify-content: center;
  }
  .d-grid_4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
