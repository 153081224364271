:root {
  --black: #020202;
  --white: white;
  --red: #c92d47;
  --green: #106b2c;
  --back: #f0bfd6;
  --front: #020202;
  --color-transition: background-color 2s ease, color 0.2s ease, fill 0.2s ease;

  --xxl: 6rem;
  --xl: 4rem;
  --l: 3rem;
  --m: 3rem;
  --s: 2rem;
  --xs: 1.5rem;
  --xxs: 1rem;
  --xxxs: 0.6rem;
}

@media (min-width: 768px) {
  :root {
    --xxl: 12rem;
    --xl: 10rem;
    --l: 7rem;
    --m: 6rem;
    --s: 4rem;
    --xs: 3rem;
    --xxs: 1.5rem;
    --xxxs: 1rem;
  }
}

@media (max-width: 374px) {
  html {
    font-size: 9px;
  }
}
@media (min-width: 375px) and (max-width: 767px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  html {
    font-size: 7px;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  html {
    font-size: 8px;
  }
}
@media (min-width: 1200px) and (max-width: 1439px) {
  html {
    font-size: 9px;
  }
}
@media (min-width: 1440px) and (max-width: 1599px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 1600px) and (max-width: 1919px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 1920px) and (max-width: 2399px) {
  html {
    font-size: 11px;
  }
}
@media (min-width: 2400px) {
  html {
    font-size: 12px;
  }
}
